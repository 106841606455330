<template>
  <div class="p-onlineClinicPatientInformation">
      <div class="p-onlineClinicPatientInformation__label">どなたが診療を受けますか？</div>
      <select v-model="selectedFamilyProfile" class="p-onlineClinicPatientInformation__selectUser">
        <option v-for="patientFamilyProfile in patientFamilyProfiles" :value="patientFamilyProfile" :key="patientFamilyProfile.id">
           {{ patientFamilyProfile.first_name }}{{patientFamilyProfile.last_name}}
        </option>
      </select>
      <div class="p-onlineClinicPatientInformation__addProfile">
        ※お子様の診察をご希望の場合には、プロフィールを追加してください
        <router-link :to="{name: 'patient_family_profile_new', params: { isFromOnlineClinicReservation: true} } " class="c-link">家族プロフィールの追加</router-link>
      </div>

    <div class="p-onlineClinicPatientInformation__container l-ignoreParentContainer">
      <div class="p-onlineClinicPatientInformation__input">
        <div class="p-onlineClinicPatientInformation__label">名前<abbr title="not_entered" v-show="isVisible && !(selectedFamilyProfile.first_name && selectedFamilyProfile.last_name)">未入力</abbr></div>
        <div>
          <input disabled type="text" v-model="selectedFamilyProfile.first_name" required>
          <input disabled type="text" v-model="selectedFamilyProfile.last_name" required>
        </div>
      </div>
      <div class="p-onlineClinicPatientInformation__input">
        <div class="p-onlineClinicPatientInformation__label">名前（フリガナ）<abbr title="not_entered" v-show="isVisible && !(selectedFamilyProfile.first_name_kana && selectedFamilyProfile.last_name_kana)">未入力</abbr></div>
        <div>
          <input disabled type="text" v-model="selectedFamilyProfile.first_name_kana" required>
          <input disabled type="text" v-model="selectedFamilyProfile.last_name_kana" required>
        </div>
      </div>

      <div class="p-onlineClinicPatientInformation__input">
        <div class="p-onlineClinicPatientInformation__label">メールアドレス<abbr title="not_entered" v-show="isVisible && !currentUser.email">未入力</abbr></div>

        <div>
          <input disabled type="email" v-model="this.currentUser.email" required>
        </div>
      </div>

      <div class="p-onlineClinicPatientInformation__input">
        <div class="p-onlineClinicPatientInformation__label">携帯番号<abbr title="not_entered" v-show="isVisible && !selectedFamilyProfile.phone_number">未入力</abbr></div>
        <div>
          <input disabled type="tel" v-model="selectedFamilyProfile.phone_number" required>
        </div>
      </div>

      <div class="p-onlineClinicPatientInformation__input">
        <div class="p-onlineClinicPatientInformation__label">誕生日<abbr title="not_entered" v-show="isVisible && !selectedFamilyProfile.birthday">未入力</abbr></div>
        <div>
          <input disabled type="date" v-model="selectedFamilyProfile.birthday" required>
        </div>
      </div>

      <div class="p-onlineClinicPatientInformation__radio">
        <div class="p-onlineClinicPatientInformation__label">性別<abbr title="not_entered" v-show="isVisible && !selectedFamilyProfile.sex">未入力</abbr></div>
        <div>
          <label><input disabled type="radio" v-model="selectedFamilyProfile.sex" value="male">男性</label>
          <label><input disabled type="radio" v-model="selectedFamilyProfile.sex" value="female">女性</label>
        </div>
      </div>

      <div class="p-onlineClinicPatientInformation__input">
        <div class="p-onlineClinicPatientInformation__label">郵便番号<abbr title="not_entered" v-show="isVisible && !selectedFamilyProfile.zip_code">未入力</abbr></div>
        <div>
          <input disabled type="text" v-model="selectedFamilyProfile.zip_code" maxlength="7" required>
        </div>
      </div>

      <div class="p-onlineClinicPatientInformation__input">
        <div class="p-onlineClinicPatientInformation__label">ご住所<abbr title="not_entered" v-show="isVisible && !selectedFamilyProfile.address">未入力</abbr></div>
        <div>
          <input disabled type="text" v-model="selectedFamilyProfile.address" required>
        </div>
      </div>

      
      <div class="p-onlineClinicPatientInformation__input">
        <div class="p-onlineClinicPatientInformation__label">基礎体温<abbr title="not_entered" v-show="isVisible && !selectedFamilyProfile.basal_body_temperature">未入力</abbr></div>
        <div class="p-onlineClinicPatientInformation__physical">
          <span>
            {{selectedFamilyProfile.basal_body_temperature ? selectedFamilyProfile.basal_body_temperature + ' &#8451;' : '　'}}
            </span>
        </div>
      </div>

      <p v-if="isValid" class="p-onlineClinicPatientInformation__link">
        ※患者さまの情報は<router-link class="c-link" :to="{name: 'patient_family_profile', params: { id: selectedFamilyProfile.id, isFromOnlineClinicReservation: true}}" @click.native="$mixpanel.track('CLICK BUTTON', { page: '病院の選択', buttonName: 'こちら', userId: currentUser.id, source: currentUser.source })">こちら</router-link>から変更してください。
      </p>
      <p v-if="!isValid" class="p-onlineClinicPatientInformation__link">
        ※予約前に<router-link class="c-link" :to="{name: 'patient_family_profile', params: { id: selectedFamilyProfile.id, isFromOnlineClinicReservation: true} }" @click.native="$mixpanel.track('CLICK BUTTON', { page: '病院の選択', buttonName: 'こちら', userId: currentUser.id, source: currentUser.source })">こちら</router-link>からすべての情報を入力してください。
      </p>
    </div>

    <div class="p-onlineClinicPatientInformation__footer">
      <button class="c-mainButton" @click="addExaminationData(); $emit('moveToNextStep'); $mixpanel.track('CLICK BUTTON', { page: '病院の選択', buttonName: '次へ', userId: currentUser.id, source: currentUser.source, isCvButton: true })" :disabled="!isValid">次へ</button>
      <button class="p-onlineClinicPatientInformation__nobutton" @click="$emit('onCancel'); $mixpanel.track('CLICK BUTTON', { page: '病院の選択', buttonName: 'キャンセル', userId: currentUser.id, source: currentUser.source })">キャンセル</button>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import {mapGetters} from "vuex";

  export default {
    data() {
      return {
        patientFamilyProfiles: [],
        selectedFamilyProfile: {},
        isVisible:false
      }
    },
    async created() {
      const res = await axios.get(`/api/patient_family_profiles`)
      this.patientFamilyProfiles = res.data
      if (this.patientId){
        var result = res.data.filter( data => data.id == this.patientId)
        this.selectedFamilyProfile = result[0]
      } else {
        this.selectedFamilyProfile = res.data[0]
      }
      this.isVisible = true
    },
    computed: {
      ...mapGetters(['currentUser']),
      isValid() {
        const requiredInputFields = [
          this.currentUser.email,
          this.selectedFamilyProfile.last_name,
          this.selectedFamilyProfile.last_name_kana,
          this.selectedFamilyProfile.first_name,
          this.selectedFamilyProfile.first_name_kana,
          this.selectedFamilyProfile.phone_number,
          this.selectedFamilyProfile.birthday,
          this.selectedFamilyProfile.sex,
          this.selectedFamilyProfile.zip_code,
          this.selectedFamilyProfile.address,
          this.selectedFamilyProfile.basal_body_temperature
        ]
        return !requiredInputFields.some(field => (field == null || field == ''))
      },
      currentUrl() {
        return location.href
      },
      patientId() {
        return this.$route.params.patientId
      }
    },
    methods: {
      addExaminationData() {
        const data = {
          selectedFamilyProfile: {...this.selectedFamilyProfile, ...{ email: this.currentUser.email }},
        }
        this.$emit('addExaminationData', data)
      },
    }
  }
</script>
