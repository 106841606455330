<template>
  <div>
    <create-wizard @clinicReservationConfirmed="createClinicReservation" :apiError="apiError" :apiCreditError="apiCreditError" :apiDoctorScheduleConflictError="apiDoctorScheduleConflictError" :apiDoubleReservationError="apiDoubleReservationError" :apiReservationDeadlineError="apiReservationDeadlineError"></create-wizard>
  </div>
</template>

<script>
  import CreateWizard from '@/components/online_clinic/clinics/reservations/CreateWizard'
  import axios from 'axios'
  import { loadStripe } from '@stripe/stripe-js';
  import VueLocalStorage from 'vue-localstorage'
  export default {
    components: {
      CreateWizard
    },
    data() {
      return {
        apiError: null,
        apiCreditError: null,
        apiDoctorScheduleConflictError: null,
        apiDoubleReservationError: null,
        apiReservationDeadlineError: null,
        stripe: null
      }
    },
    async created() {
      const token = document
        .getElementsByName('stripe-public-key')[0]
        .getAttribute('content');
      this.stripe = await loadStripe(token);
    },
    methods: {
      async createClinicReservation(reservationFormattedParams, callback) {
        this.apiDoctorScheduleConflictError = null
        this.apiDoubleReservationError = null
        this.apiReservationDeadlineError = null

        try {
          const paymentIntent = await axios.post(`/api/stripe/payment_intents`)
          const { client_secret, payment_intent_id } = paymentIntent.data
          const { error } = await this.stripe.confirmCardPayment(client_secret);
          if (error) {
            this.apiError = true
            callback()
            return
          }

          const examination = await axios.post(`/api/online_clinic/clinic_reservations`, { reservations: reservationFormattedParams, payment_intent_id: payment_intent_id })
          this.$localStorage.set('flashType', 'info')
          this.$localStorage.set('flashMessage', '下記の内容で予約完了しました')
          window.location.href = `/online_clinic/examinations/${examination.data.id}?complete_reservation`
        } catch (error) {
          if (error.response.status === 409) {
            this.apiDoctorScheduleConflictError = true
          } else if (error.response.status === 422 && error.response.data && error.response.data.error_type == 'double_reservation_error') {
            this.apiDoubleReservationError = true
          } else if (error.response.status === 422 && error.response.data && error.response.data.error_type == 'reservation_deadline_error') {
            this.apiReservationDeadlineError = true
          } else if (error.response.data.type === 'creditError') {
            alert("クレジットカードの有効性を確認できませんでした。")
            this.apiCreditError = true
          } else {
            this.apiError = true
          }
          callback()
        }
      }
    }
  }
</script>
