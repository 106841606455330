<template>
  <div class="p-onlineClinicInterview">
    <loading :translucent="true" v-show="loading"></loading>
    <interview-autofill-confirm-modal v-if="isConfirmDialogOpen" @close-modal="isConfirmDialogOpen = false" @set-latest-interview="setLatestInterview"></interview-autofill-confirm-modal>
    <button v-if="!isFirstInterview" class="c-mainButton p-onlineClinicInterview__latestInterview" @click="isConfirmDialogOpen = true">前回の問診をコピーする</button>
    <div>
      <div class="p-onlineClinicInterview__input">
        <label for="from_pharmacy_address" class="p-onlineClinicInterview__label">お薬を受け取る薬局を選択してください
        <abbr title="required">必須</abbr></label>

        <select class="p-onlineClinicInterview__select" v-model="form.selectedPharmacy" :disabled="restrictInput.pharmacy">
          <option v-for="pharmacy in pharmacies" :value="pharmacy" :key="pharmacy.id">
            {{ pharmacy.name }}
          </option>
        </select>
        <div v-if="pharmacies.length === 0" class="no-pharmacies-text">
          近くに選択できる薬局がありません。
        </div>
        <p class='supplement'>
          ※「おくすりおうち便」は処方薬の配送サービスです。<br>
          <a href="https://anamne.wraptas.site/a3c9ec905cbc4fdaa43e37314886ce3e" target="_blank" rel="noopener noreferrer">
            → 詳しくはこちら
          </a>
        </p>
        <p class='supplement'>ご自宅住所付近の薬局を表示しています。別の場所で受け取りたい場合、住所入力で薬局を検索できます。</p>
        <input id="from_pharmacy_address"
               v-model="form.pharmacyAddress"
               placeholder='例) 東京都港区赤坂'>
        <button class="c-mainButton" @click="changePharmacyAddress(); $mixpanel.track('CLICK BUTTON', { page: '問診の回答', buttonName: '薬局を探す', userId: currentUser.id, source: currentUser.source })">薬局を探す</button>
      </div>
      <GoogleMap
        :lat="mapAddressLat"
        :lng="mapAddressLng"
        :pharmacies="pharmacies"
        v-if="mapShowable"/>
      <div class="p-onlineClinicInterview__iconDescription">
        <img src="@/../assets/images/google_map/anamne.svg" alt="">
        連携薬局
        <!-- <img src="@/../assets/images/google_map/other.svg" alt="">
        その他の薬局 -->
      </div>
      <!-- <div class="p-onlineClinicInterview__pharmacyDescription">
        <img src="@/../assets/images/online_clinic/question.svg" alt="">
        「アナムネ連携薬局」とは
        <p>
        アナムネと提携しFAXによる処方せんを受け付けている薬局です。確認の手間なく薬のお受け取りが可能です。
        </p>
      </div> -->
      <!-- <div class="p-onlineClinicInterview__pharmacyGuidance" @click="isPharmacyGuidanceOpen = true">
        <p>その他の薬局を選択される場合は、 <br>こちらをご確認ください</p>
        <img src="@/../assets/images/online_clinic/right_triangle.svg" alt="">
      </div>
      <pharmacy-guidance v-if="isPharmacyGuidanceOpen" @close-modal="isPharmacyGuidanceOpen = false"></pharmacy-guidance> -->
    </div>

    <div class="p-onlineClinicInterview__radio">
      <div class="p-onlineClinicInterview__label">初診ですか？</div>
      <label><input type="radio" v-model="form.isFirstVisit" value="yes" :disabled="restrictInput.isFirstVisit">はい</label>
      <label><input type="radio" v-model="form.isFirstVisit" value="no" :disabled="restrictInput.isFirstVisit">いいえ</label>
    </div>

    <div class="p-onlineClinicInterview__input">
      <label for="symptom" class="p-onlineClinicInterview__label">どのような症状でご来院されましたか？
        <abbr title="required">必須</abbr>
      </label>
      <textarea id="symptom" v-model="form.symptom" maxlength="1000" :disabled="restrictInput.symptom"></textarea>
    <div class="p-onlineClinicInterview__attention">
    ヘルペス外来を受診の場合は、「部位」「その部位で初めて 医師に診断された年齢や時期」を詳しく入力してください
    </div>
    </div>
    <div class="p-onlineClinicInterview__input">
      <label for="from_when" class="p-onlineClinicInterview__label">それはいつ頃からですか？
        <abbr title="required">必須</abbr>
      </label>
      <textarea id="from_when" v-model="form.fromWhen" maxlength="1000" :disabled="restrictInput.fromWhen"></textarea>
    </div>

    <div class="p-onlineClinicInterview__file">
      <div class="p-onlineClinicInterview__label">保険証または医療保険資格<abbr title="required">必須</abbr>
      </div>
      <div class="p-onlineClinicInterview__photoExample">写真の例</div>
      <img src="../../../../../../assets/images/online_clinic/ok.png" alt="" class="p-onlineClinicInterview__photoExampleImage">
      <img src="../../../../../../assets/images/online_clinic/ng.png" alt="" class="p-onlineClinicInterview__photoExampleImage">
      <p style="font-size:12px; color:#7F8388; margin-top:12px; margin-bottom:2px;">※マイナンバーカードをご利用の方へ</p>
      <a style="font-size:12px; color:#2196F3; text-decoration: underline;" href="https://anamne.wraptas.site/127f5cc5be6a803994e1fa8405f2a6da" target="_blank" rel="noopener noreferrer">→医療保険資格の取得方法</a>
      <input ref="insurance_card_image" id="insurance_card_image" type="file" @change="selectedInsuranceCardImage" accept="image/*" :disabled="restrictInput.insuranceCardImage">
      <label for="insurance_card_image">写真を撮ってアップロード</label>
      <div class="p-onlineClinicInterview__previewImage">
        <img v-if="form.insuranceCardImage || insuranceCardImagePreview" :src="form.insuranceCardImage || insuranceCardImagePreview">
      </div>
      <span>{{insuranceCardImageName}}</span>
    </div>
    <div class="p-onlineClinicInterview__insuranceNotice">
      <p>保険証または医療保険資格画像の確認</p>
      <p style="color:#04AAE4; font-size:12px; margin-bottom:6px;">●記号・番号などが鮮明に記載されている</p>
      <p style="color:#04AAE4; font-size:12px; margin-bottom:6px;">●有効期限がある場合、期限が切れていない</p>
      <p style="font-size:12px; line-height:21px;">※保険証の有効性を確認できない場合、保険適用外となり、全額自己負担になります。</p>
      <div class="c-checkbox">
        <input type="checkbox" id="custom" v-model="isInsuranceConfirmed" :disabled="isEdit">
        <label for="custom" class="c-checkbox__customStyle">上記を確認しました</label>
      </div>
    </div>
      
    
    <div class="p-onlineClinicInterview__file">
      <div for="recipient_certificate_image" class="p-onlineClinicInterview__label">
        乳幼児医療など、医療費の助成資格はありますか？※助成を受ける場合、資格証の画像が必要になります。
        <abbr title="required">一部の方に必須</abbr>
      </div>
      <div>
        <input ref="recipient_certificate_image" id="recipient_certificate_image" type="file"
               @change="selectedRecipientCertificateImage" accept="image/*" :disabled="restrictInput.recipientCertificateImage">
        <label for="recipient_certificate_image">写真を撮ってアップロード</label>
        <div class="p-onlineClinicInterview__previewImage" v-if="(form.recipientCertificateImageDeleteFlag === false && form.recipientCertificateImage) || recipientCertificateImagePreview">
          <img :src="form.recipientCertificateImage || recipientCertificateImagePreview">
        </div>
        <span>{{recipientCertificateImageName}}</span>
        <button v-if="(form.recipientCertificateImageDeleteFlag === false && form.recipientCertificateImage) || recipientCertificateImagePreview" type="button" class="btn btn-danger" @click="deleteRecipientCertificateImage">削除する</button>
      </div>
    </div>

    <div>
      <div for="recipient_certificate_image" class="p-onlineClinicInterview__label">
        保険会社への給付申請の予定はありますか？
        <abbr title="required">一部の方に必須</abbr>
      </div>
      <div class="c-checkbox">
        <input type="checkbox" id="is-insurance-claim-planned" v-model="form.isInsuranceClaimPlanned" :disabled="restrictInput.isInsuranceClaimPlanned">
        <label for="is-insurance-claim-planned" class="c-checkbox__customStyle">申請予定です</label>
      </div>
      <div class="p-onlineClinicInterview__insuranceNotice" style="padding: 12px;">
        <p style="font-size:12px; line-height: 19px; margin:0;">給付申請する場合、おうち病院にてサポートをしております。
        <a style="color:#2196F3; text-decoration: underline;" href="https://anamne.wraptas.site/177f5cc5be6a8046afa0cdce1f4e59ae" target="_blank" rel="noopener noreferrer">詳しくはこちら</a>
        </p>
      </div>
    </div>

    <!-- <div class="p-onlineClinicInterview__file">
      <div for="health_diagnosis_image" class="p-onlineClinicInterview__label">
        健康診断書画像
        ※健康診断書画像を添付して基礎疾患の把握ができると、お薬の処方日数を対面診療と同等の期間分処方してもらうことができます(オンライン診療の場合はお薬の処方日数が原則7日間分とされています)
      </div>
      <div>
        <input ref="health_diagnosis_image" id="health_diagnosis_image" type="file"
               @change="selectedHealthDiagnosisImage" accept="image/*" :disabled="restrictInput.healthDiagnosisImage">
        <label for="health_diagnosis_image">写真を撮ってアップロード</label>
        <span v-if="!(form.isCopyHealthDiagnosisImage && clinicReservationToCreate.latestInterview.health_diagnosis_image.url)">{{healthDiagnosisImageName}}</span>
        <span v-else>変更がない場合、前回いただいた画像を登録します</span>
      </div>
    </div> -->

    <div class="p-onlineClinicInterview__input">
      <div class="p-onlineClinicInterview__label">身長（cm）を入力してください
        <abbr title="required">必須</abbr>
      </div>
      <div class="p-onlineClinicInterview__number">
        <input 
          type="number" 
          v-model="form.height" 
          min="0" 
          step="1"
          :disabled="restrictInput.height" 
        /><span>cm</span>
      </div>
    </div>

    <div class="p-onlineClinicInterview__input">
      <div class="p-onlineClinicInterview__label">体重（kg）を入力してください
        <abbr title="required">必須</abbr>
      </div>
      <div class="p-onlineClinicInterview__number">
        <input 
          type="number" 
          v-model="form.weight" 
          min="0" 
          step="1"
          :disabled="restrictInput.weight" 
        /><span>kg</span>
      </div>
    </div>

    <div class="p-onlineClinicInterview__radio" v-if="isFemale">
      <div class="p-onlineClinicInterview__label">現在妊娠している、またはその可能性はありますか？<abbr title="required">必須</abbr></div>
      <label><input type="radio" v-model="form.isPossibilityOfPregnancy" value="はい" :disabled="restrictInput.isPossibilityOfPregnancy">はい</label>
      <label><input type="radio" v-model="form.isPossibilityOfPregnancy" value="いいえ" :disabled="restrictInput.isPossibilityOfPregnancy">いいえ</label>
      <label><input type="radio" v-model="form.isPossibilityOfPregnancy" value="不明" :disabled="restrictInput.isPossibilityOfPregnancy">不明</label>
      <label><input type="radio" v-model="form.isPossibilityOfPregnancy" value="閉経" :disabled="restrictInput.isPossibilityOfPregnancy">閉経</label>
    </div>

    <div class="p-onlineClinicInterview__checkboxSection">
      <label for="medical_history" class="p-onlineClinicInterview__label">治療中、あるいは今までに病気や手術したことはありますか？
        <abbr title="required">必須</abbr>
      </label>
      <div v-for="option in medicalHistoryOptions" class="p-onlineClinicInterview__checkbox" :key="option">
        <label>
          <input type="checkbox" :value="option" v-model="form.medicalHistorySelect" :disabled="restrictInput.medicalHistorySelect">
          {{option}}
        </label>
      </div>
    </div>

    <div class="p-onlineClinicInterview__input" v-if="form.medicalHistorySelect.indexOf('その他') != -1">
      病名：<textarea id="current_medicine" v-model="form.medicalHistory" :disabled="restrictInput.medicalHistory" maxlength="1000"></textarea>
    </div>
    <div class="p-onlineClinicInterview__checkboxSection">
      <label for="medical_history" class="p-onlineClinicInterview__label">アレルギーはありますか？
        <abbr title="required">必須</abbr>
      </label>
      <div v-for="option in allergyOptions" class="p-onlineClinicInterview__checkbox" :key="option">
        <label>
          <input type="checkbox" :value="option" v-model="form.allergySelect" :disabled="restrictInput.allergySelect">
          {{option}}
        </label>
      </div>
    </div>
    <div class="p-onlineClinicInterview__input" v-if="form.allergySelect.indexOf('その他') != -1">
      アレルギーの種類：<textarea id="current_medicine" v-model="form.allergy" :disabled="restrictInput.allergy" maxlength="1000"></textarea>
    </div>

    <div class="p-onlineClinicInterview__radio">
      <div class="p-onlineClinicInterview__label">タバコは吸いますか？
        <abbr title="required">必須</abbr>
      </div>
      <label v-for="option in smokingOptions" :key="option">
        <input type="radio" v-model="form.smoking" :value="option" :disabled="restrictInput.smoking">{{option}}
      </label>
      <div v-if="form.smoking == '過去に吸っていた'">
        <input type="number" min="1" v-model="form.smokingPastAgeFrom" :disabled="restrictInput.smoking">〜<input type="number" min="1" v-model="form.smokingPastAgeTo" :disabled="restrictInput.smoking">才まで、1日<input type="number" min="1" v-model="form.smokingPastNumber" :disabled="restrictInput.smoking">本
      </div>
      <div v-if="form.smoking == '吸っている'">
        <input type="number" min="1" v-model="form.smokingPresentAgeFrom" :disabled="restrictInput.smoking">才から、1日<input type="number" min="1" v-model="form.smokingPresentNumber" :disabled="restrictInput.smoking">本
      </div>
    </div>

    <div class="p-onlineClinicInterview__radio">
      <label for="current_medicine" class="p-onlineClinicInterview__label">現在飲んでいるお薬はありますか？
        <abbr title="required">必須</abbr>
      </label>
      <label>
        <input type="radio" v-model="form.isCurrentMedicine" value="なし" :disabled="restrictInput.isCurrentMedicine">なし
      </label>
      <label>
        <input type="radio" v-model="form.isCurrentMedicine" value="あり" :disabled="restrictInput.isCurrentMedicine">あり
      </label>
    </div>
    <div class="p-onlineClinicInterview__input" v-if="form.isCurrentMedicine == 'あり'">
      薬名：<textarea id="current_medicine" v-model="form.currentMedicine" :disabled="restrictInput.currentMedicine" maxlength="1000"></textarea>
    </div>

    <div class="p-onlineClinicInterview__file">
      <div for="recipient_certificate_image" class="p-onlineClinicInterview__label">
        最新のお薬手帳のページをアップロードしてください
        <abbr title="required">一部の方に必須</abbr>
      </div>
      <div class="p-onlineClinicInterview__insuranceNotice" style="padding: 12px;">
        <p style="font-size:12px; line-height: 19px; margin:0;">※8日以上の処方希望の場合は、お薬手帳の記録が必要です。処方日数は医師の診断により決定されます。</p>
      </div>
      <div>
        <input ref="medicine_notebook_image" id="medicine_notebook_image" type="file"
               @change="selectedMedicineNotebookImage" accept="image/*" :disabled="restrictInput.medicineNotebookImage">
        <label for="medicine_notebook_image">写真を撮ってアップロード</label>
        <div class="p-onlineClinicInterview__previewImage" v-if="(form.medicineNotebookImageDeleteFlag === false && form.medicineNotebookImage) || medicineNotebookImagePreview">
          <img :src="form.medicineNotebookImage || medicineNotebookImagePreview">
        </div>
        <span v-if="!(form.isCopyMedicineNotebookImage && clinicReservationToCreate.latestInterview.medicine_notebook_image.url)">{{medicineNotebookImageName}}</span>
        <span v-else>変更がない場合、前回いただいた画像を登録します</span>
        <button v-if="(form.medicineNotebookImageDeleteFlag === false && form.medicineNotebookImage) || medicineNotebookImagePreview" type="button" class="btn btn-danger" @click="deleteMedicineNotebookImage">削除する</button>
      </div>
    </div>

    <div class="p-onlineClinicInterview__radio">
      <label for="current_medicine" class="p-onlineClinicInterview__label">お薬の副作用が起きたことはありますか？</label>
      <label>
        <input type="radio" v-model="form.isSideEffect" value="なし" :disabled="restrictInput.isSideEffect">なし
      </label>
      <label>
        <input type="radio" v-model="form.isSideEffect" value="あり" :disabled="restrictInput.isSideEffect">あり
      </label>
    </div>
    <div class="p-onlineClinicInterview__input" v-if="form.isSideEffect == 'あり'">
      副作用：<textarea id="side_effect" v-model="form.sideEffect" maxlength="1000" :disabled="restrictInput.sideEffect"></textarea>
    </div>

    <div class="p-onlineClinicInterview__radio">
      <div class="p-onlineClinicInterview__label">ジェネリック医薬品を希望しますか？<abbr title="required">必須</abbr></div>
      <label><input type="radio" v-model="form.generic" value="yes" :disabled="restrictInput.generic">ジェネリック薬を希望</label>
      <label><input type="radio" v-model="form.generic" value="no" :disabled="restrictInput.generic">先発薬を希望</label>
      <label><input type="radio" v-model="form.generic" value="whichever" :disabled="restrictInput.generic">どちらでもよい</label>
    </div>

    <div class="p-onlineClinicInterview__radio">
      <div class="p-onlineClinicInterview__label">現在飲んでいるサプリメントはありますか？</div>
      <label>
        <input type="radio" v-model="form.isCurrentSupplement" value="なし" :disabled="restrictInput.isCurrentSupplement">なし
      </label>
      <label>
        <input type="radio" v-model="form.isCurrentSupplement" value="あり" :disabled="restrictInput.isCurrentSupplement">あり
      </label>
    </div>
    <div class="p-onlineClinicInterview__input" v-if="form.isCurrentSupplement == 'あり'">
      サプリメント名：<textarea id="side_effect" v-model="form.currentSupplement" maxlength="1000" :disabled="restrictInput.currentSupplement"></textarea>
    </div>

    <div class="p-onlineClinicInterview__input">
      <label for="other_information" class="p-onlineClinicInterview__label">医師に伝えたいことや質問がありますか？</label>
      <textarea id="other_information" v-model="form.otherInformation" maxlength="1000" :disabled="restrictInput.otherInformation"></textarea>
    </div>

    <div class="p-onlineClinicInterview__file">
      <div for="other_useful_image" class="p-onlineClinicInterview__label">その他、医師へ症状を伝えるために役立つ情報</div>
      <div class="p-onlineClinicInterview__insuranceNotice" style="padding: 12px;">
        <p style="font-size:12px; line-height: 19px; margin:0;">※過去の該当する症状のお薬手帳など、<span style="color:#FC5AA7; display:inline; font-weight:500; font-size:12px;">花粉症外来で8日以上の処方を希望される場合</span>は必ず添付ください</p>
      </div>
      <div>
        <input ref="other_useful_image" id="other_useful_image" type="file"
               @change="selectedOtherUsefulImage" accept="image/*" :disabled="restrictInput.otherUsefulImage">
        <label for="other_useful_image">写真を撮ってアップロード</label>
        <div class="p-onlineClinicInterview__previewImage" v-if="(form.otherUsefulImageDeleteFlag === false && form.otherUsefulImage) || otherUsefulImagePreview">
          <img :src="form.otherUsefulImage || otherUsefulImagePreview">
        </div>
        <span v-if="!(form.isCopyOtherUsefulImage && clinicReservationToCreate.latestInterview.other_useful_image.url)">{{otherUsefulImageName}}</span>
        <span v-else>変更がない場合、前回いただいた画像を登録します</span>
        <button v-if="(form.otherUsefulImageDeleteFlag === false && form.otherUsefulImage) || otherUsefulImagePreview" type="button" class="btn btn-danger" @click="deleteOtherUsefulImage">削除する</button>
      </div>
    </div>



    <div v-if="!isEdit">
      <div class="p-onlineClinicInterview__footer">
        <button class="c-mainButton" @click="addExaminationData(); $emit('moveToNextStep'); $mixpanel.track('CLICK BUTTON', { page: '問診の回答', buttonName: '次へ', userId: currentUser.id, source: currentUser.source, isCvButton: true })"
                :disabled="!isValid">次へ
        </button>
        <button class="p-onlineClinicInterview__nobutton" @click="$emit('onCancel'); $mixpanel.track('CLICK BUTTON', { page: '問診の回答', buttonName: 'キャンセル', userId: currentUser.id, source: currentUser.source })">キャンセル</button>
      </div>
    </div>
    <div v-if="isEdit">
      <div class="p-onlineClinicInterview__footer">
        <button class="c-mainButton" @click="emitUpdateExamination(); $mixpanel.track('CLICK BUTTON', { page: '問診修正', buttonName: '保存', userId: currentUser.id, source: currentUser.source })"
                :disabled="!isValid">保存
        </button>
        <button class="p-onlineClinicInterview__nobutton" @click="$emit('onCancel'); $mixpanel.track('CLICK BUTTON', { page: '問診修正', buttonName: 'キャンセル', userId: currentUser.id, source: currentUser.source })">キャンセル</button>
      </div>
   </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from 'axios';
import GoogleMap from '@/components/online_clinic/GoogleMap'
import Loading from '@/components/Common/Loading';
import PharmacyGuidance from '@/components/online_clinic/clinics/reservations/interviewForm/PharmacyGuidance';
import InterviewAutofillConfirmModal from '@/components/online_clinic/clinics/reservations/dialog/InterviewAutofillConfirmModal'
import {mapGetters} from "vuex";

const MEDICATION_MAIL_OPTION = { name: "おくすりおうち便(+500円)[NEW]", id: "medication_mail" };
const SELECT_OPTION = { name: '選択してください', id: null, };
const COVID19_ID = 56
const TOKYO_ID = 13

export default {
  components: { GoogleMap, Loading, PharmacyGuidance, InterviewAutofillConfirmModal },
  props: {
    clinicReservationToCreate: {
      type: Object,
      required: true,
    },
    examination: {
      type: Object,
    },
    isEdit: {
      type: Boolean,
    }
  },
  data() {
    return {
      pharmacies: [],
      recipientCrtificateImageRequired: 'yes',
      mapAddressLat: 0,
      mapAddressLng: 0,
      mapShowable: false,
      loading: false,
      insuranceCardImageName: '',
      recipientCertificateImageName: '',
      medicineNotebookImageName: '',
      otherUsefulImageName: '',
      healthDiagnosisImageName: '',
      isPharmacyGuidanceOpen: false,
      isConfirmDialogOpen: false,
      medicalHistoryOptions: [
        'なし',
        '高血圧',
        '緑内障',
        '糖尿病',
        '喘息',
        '心疾患',
        '呼吸器疾患',
        '脳疾患',
        '肝臓病',
        '腎臓病',
        '胃腸の疾患',
        '精神疾患',
        '慢性閉塞性肺疾患(COPD)',
        'その他'
      ],
      allergyOptions: [
        'なし',
        '食物アレルギー',
        'アトピー性皮膚炎',
        '気管支喘息',
        '湿疹',
        'アレルギー性鼻炎',
        '花粉症',
        'じんましん',
        '薬物アレルギー',
        'その他',
      ],
      smokingOptions: [
        '吸わない',
        '過去に吸っていた',
        '吸っている'
      ],
      form: {
        isFirstVisit: 'yes',
        symptom: null,
        fromWhen: null,
        insuranceCardImage: null,
        recipientCertificateImage: null,
        recipientCertificateImageDeleteFlag: false,
        medicineNotebookImage: null,
        medicineNotebookImageDeleteFlag: false,
        otherUsefulImage: null,
        otherUsefulImageDeleteFlag: false,
        healthDiagnosisImage: null,
        medicalHistorySelect: [],
        currentMedicine: null,
        allergy: null,
        allergySelect: [],
        smoking: null,
        smokingPastNumber: null,
        smokingPastAgeFrom: null,
        smokingPastAgeTo: null,
        smokingPresentNumber: null,
        smokingPresentAgeFrom: null,
        isCurrentMedicine: null,
        otherInformation: null,
        isSideEffect: null,
        sideEffect: null,
        generic: null,
        isCurrentSupplement: null,
        currentSupplement: null,
        selectedPharmacy: null,
        profileLatLngNull: '選択なし',
        pharmacyAddress: '',
        isCopyMedicineNotebookImage: false,
        isCopyOtherUsefulImage: false,
        isCopyHealthDiagnosisImage : false,
        height: null,
        weight: null,
        isPossibilityOfPregnancy: 'いいえ',
        isInsuranceClaimPlanned: false
      },
      restrictInput: {
        isFirstVisit: false,
        symptom: false,
        fromWhen: false,
        insuranceCardImage: false,
        recipientCertificateImage: false,
        medicineNotebookImage: false,
        otherUsefulImage: false,
        healthDiagnosisImage: false,
        medicalHistorySelect: false,
        medicalHistory: false,
        allergySelect: false,
        allergy: false,
        smoking: false,
        isCurrentMedicine: false,
        currentMedicine: false,
        otherInformation: false,
        isSideEffect: false,
        sideEffect: false,
        generic: false,
        isCurrentSupplement: false,
        currentSupplement: false,
        pharmacy: false,
        height: false,
        weight: false,
        isPossibilityOfPregnancy: false,
        isInsuranceClaimPlanned: false
      },
      insuranceCardImagePreview: '',
      recipientCertificateImagePreview: '',
      medicineNotebookImagePreview: '',
      otherUsefulImagePreview: '',
      isInsuranceConfirmed: false
    };
  },
  computed: {
    isValid() {
      let insuranceCardImageValidation;
      this.isEdit ? insuranceCardImageValidation = true : insuranceCardImageValidation = this.form.insuranceCardImage
      return (
        this.form.symptom &&
        this.form.fromWhen &&
        this.form.medicalHistorySelect.length > 0 &&
        this.form.allergySelect.length > 0 &&
        this.form.smoking &&
        this.form.isCurrentMedicine &&
        this.form.height &&
        this.form.weight &&
        this.form.generic &&
        (!this.isFemale || this.form.isPossibilityOfPregnancy) &&
        // this.form.isPossibilityOfPregnancy &&
        this.form.selectedPharmacy.id &&
        insuranceCardImageValidation &&
        this.isInsuranceConfirmed
      );
    },
    isFirstInterview() {
      return !this.clinicReservationToCreate.latestInterview
    },
    isMedicationMailAvailable() {
      if (this.isEdit){
        return !(this.examination.medical_department_id == COVID19_ID && this.examination.patient_family_profile.address_prefecture_id != TOKYO_ID )
      } else {
        return !(this.clinicReservationToCreate.medicalDepartment.id == COVID19_ID && this.clinicReservationToCreate.selectedFamilyProfile.address_prefecture_id != TOKYO_ID )
      }
    },
    isFemale() {
      if (this.isEdit) {
        return this.examination.patient_family_profile.sex == 'female'
      } else {
        return this.clinicReservationToCreate.selectedFamilyProfile.sex == 'female'
      }
    },
    ...mapGetters(['currentUser'])
  },
  async created() {
    if (!this.isFirstInterview){
      this.form.isFirstVisit = 'no'
    }
    await this.fetchSelectablePharmacies(null);
    await this.fetchMapCenter(null);
    this.mapShowable = true;
    if (this.isEdit) {
      this.setExamination()
      this.setRestrictInput()
      this.isInsuranceConfirmed = true
    }
  },
  methods: {
    async fetchSelectablePharmacies(address) {
      try {
        const res = await axios.get(`/api/online_clinic/pharmacies`,
          { params: { address: address }});
        this.pharmacies = res.data;
        // おうち便を追加
        if (this.isMedicationMailAvailable) {
          this.pharmacies.unshift(MEDICATION_MAIL_OPTION);
        }
        this.pharmacies.unshift(SELECT_OPTION)
        // 初期値を設定
        this.form.selectedPharmacy = SELECT_OPTION
      } catch {
        alert('ERROR');
      }
    },
    async selectedInsuranceCardImage(e) {
      e.preventDefault();
      const files = e.target.files;
      const file = files[0],
        type = file.type

        //拡張子heicとpdfはアップできないように制御
        if (type == 'image/heic' || type == 'application/pdf') {
          alert('.heic、.pdfのファイルはアップロードできません')
          event.currentTarget.value = ''
        }
      const image = await this.getBase64(files[0]);
      this.form.insuranceCardImage = image;
      this.insuranceCardImageName = files[0].name
    },
    async selectedRecipientCertificateImage(e) {
      e.preventDefault();
      const files = e.target.files;
      const file = files[0],
        type = file.type

        //拡張子heicとpdfはアップできないように制御
        if (type == 'image/heic' || type == 'application/pdf') {
          alert('.heic、.pdfのファイルはアップロードできません')
          event.currentTarget.value = ''
        }
      const image = await this.getBase64(files[0]);
      this.form.recipientCertificateImage = image;
      this.recipientCertificateImageName = files[0].name
      this.form.recipientCertificateImageDeleteFlag = false
    },
    async selectedMedicineNotebookImage(e) {
      e.preventDefault();
      const files = e.target.files;
      const file = files[0],
        type = file.type

        //拡張子heicとpdfはアップできないように制御
        if (type == 'image/heic' || type == 'application/pdf') {
          alert('.heic、.pdfのファイルはアップロードできません')
          event.currentTarget.value = ''
        }
      const image = await this.getBase64(files[0]);
      this.form.medicineNotebookImage = image;
      this.medicineNotebookImageName = files[0].name
      this.$set(this.form, "isCopyMedicineNotebookImage", false)
      this.form.medicineNotebookImageDeleteFlag = false
    },
    async selectedOtherUsefulImage (e) {
      e.preventDefault();
      const files = e.target.files;
      const file = files[0],
        type = file.type

        //拡張子heicとpdfはアップできないように制御
        if (type == 'image/heic' || type == 'application/pdf') {
          alert('.heic、.pdfのファイルはアップロードできません')
          event.currentTarget.value = ''
        }
      const image = await this.getBase64(files[0]);
      this.form.otherUsefulImage = image;
      this.otherUsefulImageName = files[0].name
      this.$set(this.form, "isCopyOtherUsefulImage", false)
      this.form.otherUsefulImageDeleteFlag = false
    },
    async selectedHealthDiagnosisImage(e) {
      e.preventDefault();
      const files = e.target.files;
      const file = files[0],
        type = file.type

        //拡張子heicとpdfはアップできないように制御
        if (type == 'image/heic' || type == 'application/pdf') {
          alert('.heic、.pdfのファイルはアップロードできません')
          event.currentTarget.value = ''
        }
      const image = await this.getBase64(files[0]);
      this.form.healthDiagnosisImage = image;
      this.healthDiagnosisImageName = files[0].name
      this.$set(this.form, "isCopyHealthDiagnosisImage", false)
    },
    async fetchMapCenter(address){
      try {
        const res = await axios.get(`/api/online_clinic/geocoders/fetch_latlng_from_user_address`, {params: { address: address }})
        this.mapAddressLat = res.data['lat'];
        this.mapAddressLng = res.data['lng'];
      } catch {
        alert('ERROR');
      }
    },
    async changePharmacyAddress(){
      try{
        this.mapShowable = false;
        this.loading = true
        await this.fetchMapCenter(this.form.pharmacyAddress)
        await this.fetchSelectablePharmacies(this.form.pharmacyAddress)
        this.mapShowable = true;
        this.loading = false
      }catch{
        alert('ERROR');
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    setCheckbox(str, ary) {
      return ary.concat(str.split(','));
    },
    addExaminationData() {
      const data = {
        interview: this.form,
      };
      this.$emit('addExaminationData', data);
    },
    setLatestInterview() {
      this.form.symptom = this.clinicReservationToCreate.latestInterview.symptom
      this.form.fromWhen = this.clinicReservationToCreate.latestInterview.from_when
      if (this.clinicReservationToCreate.latestInterview.medical_history_select) {
        this.form.medicalHistorySelect = this.clinicReservationToCreate.latestInterview.medical_history_select.split(',')
      }
      this.form.medicalHistory = this.clinicReservationToCreate.latestInterview.medical_history
      if (this.clinicReservationToCreate.latestInterview.allergy_select) {
        this.form.allergySelect = this.clinicReservationToCreate.latestInterview.allergy_select.split(',')
      }
      this.form.allergy = this.clinicReservationToCreate.latestInterview.allergy
      this.form.smoking = this.clinicReservationToCreate.latestInterview.smoking
      this.form.smokingPastAgeFrom = this.clinicReservationToCreate.latestInterview.smoking_past_age_from
      this.form.smokingPastAgeTo = this.clinicReservationToCreate.latestInterview.smoking_past_age_to
      this.form.smokingPastNumber = this.clinicReservationToCreate.latestInterview.smoking_past_number
      this.form.smokingPresentAgeFrom = this.clinicReservationToCreate.latestInterview.smoking_present_age_from
      this.form.smokingPresentNumber = this.clinicReservationToCreate.latestInterview.smoking_present_number
      this.form.isCurrentMedicine = this.clinicReservationToCreate.latestInterview.is_current_medicine
      this.form.currentMedicine = this.clinicReservationToCreate.latestInterview.current_medicine
      this.form.otherInformation = this.clinicReservationToCreate.latestInterview.other_information
      this.form.isSideEffect = this.clinicReservationToCreate.latestInterview.is_side_effect
      this.form.sideEffect = this.clinicReservationToCreate.latestInterview.side_effect
      this.form.generic = this.clinicReservationToCreate.latestInterview.generic
      this.form.isCurrentSupplement = this.clinicReservationToCreate.latestInterview.is_current_supplement
      this.form.currentSupplement = this.clinicReservationToCreate.latestInterview.current_supplement
      this.form.height = this.clinicReservationToCreate.latestInterview.height
      this.form.weight = this.clinicReservationToCreate.latestInterview.weight
      this.form.isInsuranceClaimPlanned = this.clinicReservationToCreate.latestInterview.is_insurance_claim_planned
      if (this.clinicReservationToCreate.latestInterview.is_possibility_of_pregnancy != null) {
        this.form.isPossibilityOfPregnancy = this.clinicReservationToCreate.latestInterview.is_possibility_of_pregnancy;
      } else {
        //nullの場合は不明
        this.form.isPossibilityOfPregnancy = "不明";
      }

      this.medicineNotebookImagePreview = this.clinicReservationToCreate.latestInterview.medicine_notebook_image.url
      this.otherUsefulImagePreview = this.clinicReservationToCreate.latestInterview.other_useful_image.url

      //薬局の設定
      this.form.selectedPharmacy = SELECT_OPTION
      // } else {
      //   // 郵送の場合
      //   // this.form.selectedPharmacy = {name: '処方箋を郵便で受け取る', id: null}
      // }

      //画像について前回のものをコピーするフラグを設定
      if (this.clinicReservationToCreate.latestInterview.medicine_notebook_image.url) {
        this.form.isCopyMedicineNotebookImage = true
      }
      if (this.clinicReservationToCreate.latestInterview.other_useful_image.url) {
        this.form.isCopyOtherUsefulImage = true
      }

      //画像について中身を初期化
      this.medicineNotebookImageName = null
      this.form.medicineNotebookImage = null
      this.$refs.medicine_notebook_image.value = null

      this.otherUsefulImageName = null
      this.form.otherUsefulImage = null
      this.$refs.other_useful_image.value = null

      this.form.latestInterviewId = this.clinicReservationToCreate.latestInterview.id

      this.isConfirmDialogOpen = false
    },
    setExamination() {
      this.form.isFirstVisit = this.examination.interview_for_department.is_first_visit
      this.form.symptom = this.examination.interview_for_department.symptom
      this.form.fromWhen = this.examination.interview_for_department.from_when
      this.form.medicalHistorySelect = this.setCheckbox(this.examination.interview_for_department.medical_history_select, this.form.medicalHistorySelect)

      this.form.medicalHistory = this.examination.interview_for_department.medical_history
      this.form.isCurrentMedicine = this.examination.interview_for_department.is_current_medicine
      this.form.currentMedicine = this.examination.interview_for_department.current_medicine
      this.form.allergySelect = this.setCheckbox(this.examination.interview_for_department.allergy_select, this.form.allergySelect)

      this.form.allergy = this.examination.interview_for_department.allergy
      this.form.smoking = this.examination.interview_for_department.smoking
      this.form.smokingPastAgeFrom = this.examination.interview_for_department.smoking_past_age_from
      this.form.smokingPastAgeTo = this.examination.interview_for_department.smoking_past_age_to
      this.form.smokingPastNumber = this.examination.interview_for_department.smoking_past_number
      this.form.smokingPresentAgeFrom = this.examination.interview_for_department.smoking_present_age_from
      this.form.smokingPresentNumber = this.examination.interview_for_department.smoking_present_number

      this.form.otherInformation = this.examination.interview_for_department.other_information
      this.form.isSideEffect = this.examination.interview_for_department.is_side_effect
      this.form.sideEffect = this.examination.interview_for_department.side_effect
      this.form.generic = this.examination.interview_for_department.generic
      this.form.isCurrentSupplement = this.examination.interview_for_department.is_current_supplement
      this.form.currentSupplement = this.examination.interview_for_department.current_supplement
      this.form.height = this.examination.interview_for_department.height
      this.form.weight = this.examination.interview_for_department.weight
      this.form.isPossibilityOfPregnancy = this.examination.interview_for_department.is_possibility_of_pregnancy
      this.form.isInsuranceClaimPlanned = this.examination.interview_for_department.is_insurance_claim_planned

      this.insuranceCardImagePreview = this.examination.interview_for_department.patient_insurance_card_image.url
      this.recipientCertificateImagePreview = this.examination.interview_for_department.recipient_certificate_image.url
      this.medicineNotebookImagePreview = this.examination.interview_for_department.medicine_notebook_image.url
      this.otherUsefulImagePreview = this.examination.interview_for_department.other_useful_image.url
      //薬局の設定
      if (this.examination.pharmacy){
        if (this.pharmacies.some((item) => item.id === this.examination.pharmacy.id)) {
          //もともとの薬局が選択肢としてすでに含まれている場合。追加しない
          this.form.selectedPharmacy = this.examination.pharmacy
        } else {
          this.pharmacies.unshift(this.examination.pharmacy)
          this.form.selectedPharmacy = this.examination.pharmacy
        }
      } else if (this.examination.is_medication_mail) {
        this.form.selectedPharmacy = MEDICATION_MAIL_OPTION
      }
      // } else {
      //   郵送の場合
      //   this.form.selectedPharmacy = {name: '処方箋を郵便で受け取る', id: null}
      // }
    },
    emitUpdateExamination() {
      const examinationParams = {
        examination_attributes: {
          pharmacy_id: this.form.selectedPharmacy.id
        },
        interview_attributes: {
          is_first_visit: this.form.isFirstVisit,
          symptom: this.form.symptom,
          from_when: this.form.fromWhen,
          medical_history_select: this.form.medicalHistorySelect.join(','),
          medical_history: this.form.medicalHistory,
          is_current_medicine: this.form.isCurrentMedicine,
          current_medicine: this.form.currentMedicine,
          allergy_select: this.form.allergySelect.join(','),
          allergy: this.form.allergy,
          smoking: this.form.smoking,
          smoking_past_age_from: this.form.smokingPastAgeFrom,
          smoking_past_age_to: this.form.smokingPastAgeTo,
          smoking_past_number: this.form.smokingPastNumber,
          smoking_present_age_from: this.form.smokingPresentAgeFrom,
          smoking_present_number: this.form.smokingPresentNumber,
          other_information: this.form.otherInformation,
          is_side_effect: this.form.isSideEffect,
          side_effect: this.form.sideEffect,
          generic: this.form.generic,
          is_current_supplement: this.form.isCurrentSupplement,
          current_supplement: this.form.currentSupplement,
          height: this.form.height,
          weight: this.form.weight,
          is_possibility_of_pregnancy: this.form.isPossibilityOfPregnancy,
          is_insurance_claim_planned: this.form.isInsuranceClaimPlanned,
          patient_insurance_card_image: this.form.insuranceCardImage,
          recipient_certificate_image: this.form.recipientCertificateImage,
          medicine_notebook_image: this.form.medicineNotebookImage,
          other_useful_image: this.form.otherUsefulImage,
          health_diagnosis_image: this.form.healthDiagnosisImage,
        },
        image_delete_flags: {
          recipient_certificate_image_delete_flag: this.form.recipientCertificateImageDeleteFlag,
          medicine_notebook_image_delete_flag: this.form.medicineNotebookImageDeleteFlag,
          other_useful_image_delete_flag: this.form.otherUsefulImageDeleteFlag,
        },
      }
      this.$emit('updateExamination', examinationParams)
    },
    setRestrictInput() {
      if (this.examination.billing_amount){
        //クリニック請求後は全て編集不可
        for (let key in this.restrictInput) {
          this.restrictInput[key] = true
        }
      } else if (moment(this.examination.doctor_schedule.start) < moment()) {
        //診療開始後
        for (let key in this.restrictInput) {
          if (key != 'pharmacy' && key != 'insuranceCardImage' && key != 'recipientCertificateImage'){
            this.restrictInput[key] = true
          }
        }
      }
    },
    deleteRecipientCertificateImage() {
      this.form.recipientCertificateImage = null
      this.form.recipientCertificateImageDeleteFlag = true
      this.recipientCertificateImageName = ''
      this.recipientCertificateImagePreview = ''
    },
    deleteMedicineNotebookImage() {
      this.form.medicineNotebookImage = null
      this.form.medicineNotebookImageDeleteFlag = true
      this.medicineNotebookImageName = ''
      this.medicineNotebookImagePreview = ''
    },
    deleteOtherUsefulImage() {
      this.form.otherUsefulImage = null
      this.form.otherUsefulImageDeleteFlag = true
      this.otherUsefulImageName = ''
      this.otherUsefulImagePreview = ''
    },
  },
  watch: {
    clinicReservationToCreate: function(val) {
      this.fetchSelectablePharmacies();
    },
  },
};
</script>

<style>
.no-pharmacies-text {
  color: red;
  text-align: center;
  font-weight: bold;
  margin: 8px;
}
</style>
