<template>
  <base-modal>
    <template slot="header">
      <h1>
        プラン登録
      </h1>
    </template>
    <template slot="body">
      <div class="stripeCardForm" v-if="!isCreditCardRegistered">
        <form @submit.prevent="registerCard">
          <div class="form-group">
            <label class="control-label">カード番号</label>
            <div id="card-number" class="form-control"></div>
          </div>
          <div class="form-group">
            <label class="control-label">有効期限 月/年</label>
            <div id="card-expiry" class="form-control"></div>
          </div>
          <div class="form-group">
            <label class="control-label">セキュリティコード</label>
            <div id="card-cvc" class="form-control"></div>
          </div>
          <p v-if="error" class="error-text">{{ error }}</p>
          <p>
            <img :src="visaLogo" alt="visa" style="height: 20px"/>
            <img :src="masterCardLogo" alt="masterCard" style="height: 34px"/>
            <img :src="amexLogo" alt="amex" style="height: 24px"/>
            <img :src="jcbLogo" alt="jcb" style="height: 24px"/>
            がご利用いただけます
          </p>
          <div class="subscription-plan">
            <button type="submit" :disabled="loading" class="submit">
              {{ loading ? "登録中..." : "カードを登録" }}
            </button>
          </div>
        </form>
      </div>
      <div v-else>
        <div class="subscription-plan">
          <span class="subscription-period">{{this.targetPlan.name}}</span>
          <span class="subscription-amount">{{this.targetPlan.price}}</span>
          <button class="btn subscription-submit" @click="subscribe" :disabled="loading">{{ loading ? "登録中..." : "プラン登録" }}</button>
        </div>
        <p v-if="error" class="error-text">{{ error }}</p>
      </div>
      <section class="creditCardPolicy">
        <p>【クレジットカード購入の注意事項】</p>
        <h4> 【クレジットカード決済について】 </h4>
        <ul>
          <li>
            本クレジットカード決済は決済代行サービス提供会社(Stripe社)による機能を使用して提供されます。
          </li>
          <li>
            お客様のクレジットカード番号は、SSLというセキュリティシステムを利用し暗号化されているため、漏洩等のご心配はございません。安心してサービスをご利用ください。
          </li>
          <li>
            お客様のクレジットカード情報は弊社データベースには格納されません。
          </li>
          <li>
            決済手数料は弊社負担となります。
          </li>
        </ul>
      </section>
    </template>
  </base-modal>
</template>

<script>
  import amexLogo from '@/../assets/images/cards/amex.gif'
  import visaLogo from '@/../assets/images/cards/visa.png'
  import masterCardLogo from '@/../assets/images/cards/master_card.svg'
  import jcbLogo from '@/../assets/images/cards/jcb.gif'
  import { loadStripe } from "@stripe/stripe-js";
  import { mapGetters, mapActions } from 'vuex'
  import * as actionTypes  from '../store/action-types'
  import PlanTypes from '@/const/plan_types'
  import axios from "axios";

  export default {
    name: 'StripeCardForm',
    computed: {
      amexLogo: () => amexLogo,
      visaLogo: () => visaLogo,
      masterCardLogo: () => masterCardLogo,
      jcbLogo: () => jcbLogo,
      ...mapGetters(['currentUser']),
      isCreditCardRegistered() {
        return this.currentUser.is_creditcard_registered
      },
      targetPlan() {
        if (this.selectedPlanType == PlanTypes.BASIC_PLAN) {
          return { name: "相談し放題", price: "980円（税込）"}
        } else if (this.selectedPlanType == PlanTypes.TRIAL_PLAN) {
          return { name: "相談し放題", price: "980円（税込）"}
        } else if (this.selectedPlanType == PlanTypes.KANPO_PLAN) {
          return { name: "相談し放題", price: "980円（税込）"}
        } else if (this.selectedPlanType == PlanTypes.STANDARD_PLAN) {
          return { name: "スタンダードプラン", price: "7,700円（税込）"}
        } else if (this.selectedPlanType == PlanTypes.PREMIUM_PLAN) {
          return { name: "プレミアムプラン", price: "16,500円（税込）"}
        }
      },
      selectedPlanType() {
        return this.$route.query.plan_type
      }
    },
    async mounted() {
      const token = document
        .getElementsByName('stripe-public-key')[0]
        .getAttribute('content');
      this.stripe = await loadStripe(token);

      if (!this.isCreditCardRegistered) {
        this.elements = this.stripe.elements();

        // 個別の入力要素を作成
        this.cardNumber = this.elements.create("cardNumber");
        this.cardExpiry = this.elements.create("cardExpiry");
        this.cardCvc = this.elements.create("cardCvc");

        // 各要素をマウント
        this.cardNumber.mount("#card-number");
        this.cardExpiry.mount("#card-expiry");
        this.cardCvc.mount("#card-cvc");

        // 入力終了時に次のフィールドにフォーカスする制御
        this.cardNumber.on("change", (event) => {
          if (event.complete) {
            this.cardExpiry.focus();
          }
        });

        this.cardExpiry.on("change", (event) => {
          if (event.complete) {
            this.cardCvc.focus();
          }
        });

        // サーバーからSetupIntentのclient_secretを取得
        const response = await axios.post("/api/stripe/setup_intents");
        this.clientSecret = response.data.client_secret;
        this.setupIntentId = response.data.setup_intent_id;
      }
    },
    data () {
      return {
        stripe: null,
        elements: null,
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null,
        clientSecret: null,
        setupIntentId: null,
        error: null,
        loading: false,
      }
    },
    methods: {
      async registerCard() {
        this.error = null;
        this.loading = true;

        try {
          const { error } = await this.stripe.confirmCardSetup(
            this.clientSecret,
            {
              payment_method: {
                card: this.cardNumber,
              },
            }
          );
          if (error) {
            this.error = error.message;
          } else {
            await axios.post("/api/stripe/customer_connection", {
              setup_intent_id: this.setupIntentId,
            });
            this.$store.dispatch(actionTypes.FETCH_CURRENT_USER);
          }
        } catch (err) {
          this.error = err.message;
        } finally {
          this.loading = false;
        }
      },

      async subscribe() {
        this.error = null;
        this.loading = true;

        try {
          const { data: { client_secret } } = await this.postSubscription({ selectedPlanType: this.selectedPlanType })

          const { error } = await this.stripe.confirmCardPayment(client_secret);
          if (error) {
            this.error = error.message;
            return
          }
          this.$store.dispatch(actionTypes.FETCH_CURRENT_USER);
          await this.postChatLog({ chatLog: { content: '有料会員に登録しました' }, chatId: this.$route.params.chat_id });
          this.backChat();
        } catch (err) {
          this.error = err.message;
        } finally {
          this.loading = false;
        }
      },
      backChat() {
        this.$router.push(`/chat/${this.$route.params.chat_id}`)
      },
      ...mapActions({ postSubscription: actionTypes.POST_SUBSCRIPTION,
      postChatLog: "POST_CHAT_LOG" })
    },
  }
</script>

<style scoped lang="scss">
  .subscription-form {
    .source-radio {
      border-bottom: 1px solid #aaa;
      &:last-child {
        border-bottom: 0px solid #aaa;
      }

      input[type=radio] {
        display: none;
      }

      &-label {
        height: 40px;
        width: 100%;
        box-sizing: border-box;
        -webkit-transition: background-color 0.2s linear;
        transition: background-color 0.2s linear;
        position: relative;
        display: inline-block;
        margin: 0 20px 0px 0;
        padding: 12px 12px 12px 42px;
        background-color: #f6f7f8;
        vertical-align: middle;
        cursor: pointer;
        font-weight: normal;

        &:hover {
          background-color: #e2edd7;
          &:after {
            border-color: #53b300; }
        }

        &:before {
          -webkit-transition: opacity 0.2s linear;
          transition: opacity 0.2s linear;
          position: absolute;
          //top: 50%;
          left: 20px;
          display: block;
          //margin-top: -5px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          color: #53b300;
          content: "\f00c";
          font-family: FontAwesome;
          opacity: 0;
        }
      }

      input[type=radio]:checked + .source-radio-label:before {
        opacity: 1;
      }
    }
  }

  .subscription-plan {
    margin-top: 20px;
    position: relative;
    height : 50px;
    background-color: white;
    .subscription-period {
      font-size: 1.2em;
      height: 36px;
      line-height: 36px;

      &:after {
        content: ':';
        padding: 0 2px;
      }
    }
    .subscription-amount {
      font-size: 1.2em;
      height: 36px;
      line-height: 36px;
    }
    .subscription-submit {
      color: #fff;
      display: inline;
      background: linear-gradient(9.07deg, #00bcd4 0.06%, #2196f3 100%);
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      padding: 11px 30px;
      margin: 10px 0;
      border-radius: 50px;
      text-decoration: none;
      position: absolute;
      right: 8px;
      bottom: 0px;
    }

    @media screen and (max-width: 520px) {
      height: 96px;
      width: 100%;
      .subscription-submit {
        margin: auto;
        position: static;
        width: 100%;
        display: block;
      }
    }
  }

  .creditCardPolicy {
    border-top: 1px solid #eee;
    padding: 16px 0 0 0;
  }
</style>
