<template>
  <div>
    <div class="p-onlineClinicInterviewConfirmation__box">
      <div class="p-onlineClinicInterviewConfirmation__label">問診内容</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">月経中/月経前後の症状を教えてください</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.symptom}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">その症状が出始めたのはいつ頃からですか</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.fromWhen}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">保険証<br>※クリックすると拡大されます</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">
        <img :src="clinicReservationToCreate.interview.insuranceCardImage" @click="openImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
      </div>

      <div v-if="clinicReservationToCreate.interview.recipientCertificateImage">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">助成資格画像<br>※クリックすると拡大されます</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">
          <img :src="clinicReservationToCreate.interview.recipientCertificateImage" @click="openRecipientCertificateImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
        </div>
      </div>
      <div v-else>
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">助成資格画像</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">選択されていません</div>
      </div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">身長（cm）を入力してください</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.height}} cm</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">体重（kg）を入力してください</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.weight}} kg</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">最後に月経があったのはいつですか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.latestMenstruationDate}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">現在妊娠している、またはその可能性はありますか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.isPossibilityOfPregnancy}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">治療中、あるいは今までに病気や手術したことはありますか？</div>
      <div v-for="(item, index) in clinicReservationToCreate.interview.medicalHistorySelect" :key="`first-${index}`" class="p-onlineClinicInterviewConfirmation__selectInterview">
        <p v-if="item != 'その他'">{{item}}</p>
        <p v-if="item == 'その他'">{{item}}（{{clinicReservationToCreate.interview.medicalHistory}}）</p>
      </div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">以下の疾患に該当するものはありますか？</div>
      <div class="p-onlineClinicInterviewConfirmation__example">
肥満・過敏性素因・乳癌・子宮癌・子宮筋腫・子宮腺筋症・子宮内膜症・チョコレート嚢腫・奇形腫・深部静脈血栓症・血栓性静脈炎・肺塞栓症・脳血管障害・冠動脈疾患・片頭痛・高血圧症・心疾患・糖尿病・血栓性素因・自己免疫疾患・肝障害・肝腫瘍・高脂血症・妊娠時黄疸・妊娠時持続性そう痒症・妊娠ヘルペス・ポルフィリン症・てんかん・テタニー・クローン病・腎疾患・潰瘍性大腸炎</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.isDisease}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">血圧を教えてください</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.bloodPressure}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">2回目以降のピル処方を希望の方は、測定結果を入力してください</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.bloodPressureMax + ' / ' + clinicReservationToCreate.interview.bloodPressureMin}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">ご家族に血栓症・乳がんにかかったことのある方はいますか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.isSeriousDiseaseBefore}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">アレルギーはありますか？</div>
      <div v-for="(item, index) in clinicReservationToCreate.interview.allergySelect" :key="`second-${index}`" class="p-onlineClinicInterviewConfirmation__selectInterview">
        <p v-if="item != 'その他'">{{item}}</p>
        <p v-if="item == 'その他'">{{item}}（{{clinicReservationToCreate.interview.allergy}}）</p>
      </div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">タバコは吸いますか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{smokingText}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">現在飲んでいるお薬はありますか？</div>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-if="clinicReservationToCreate.interview.isCurrentMedicine == 'なし'">なし</p>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-if="clinicReservationToCreate.interview.isCurrentMedicine == 'あり'">あり<br>{{clinicReservationToCreate.interview.currentMedicine}}</p>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">今までに低用量ピルまたはアフターピルを服用したことはありますか？</div>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-if="clinicReservationToCreate.interview.isPillsBefore == 'なし'">なし</p>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-if="clinicReservationToCreate.interview.isPillsBefore == 'あり'">あり<br>{{clinicReservationToCreate.interview.pills}}</p>

      <div v-if="clinicReservationToCreate.interview.pillsPrescriptionImage || clinicReservationToCreate.interview.isCopyPillsPrescriptionImage">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">処方されたお薬手帳の画像<br>※クリックすると拡大されます</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">
          <img v-if="clinicReservationToCreate.interview.pillsPrescriptionImage" :src="clinicReservationToCreate.interview.pillsPrescriptionImage" @click="openPillsPrescriptionImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
          <img v-else :src="clinicReservationToCreate.latestInterview.pills_prescription_image.url" @click="openPillsPrescriptionImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
        </div>
      </div>
      <div v-else>
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">処方されたお薬手帳画像</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">選択されていません</div>
      </div>

      <div v-if="clinicReservationToCreate.interview.medicineNotebookImage || clinicReservationToCreate.interview.isCopyMedicineNotebookImage">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">最新のお薬手帳画像<br>※クリックすると拡大されます</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">
          <img v-if="clinicReservationToCreate.interview.medicineNotebookImage" :src="clinicReservationToCreate.interview.medicineNotebookImage" @click="openMedicineNotebookImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
          <img v-else :src="clinicReservationToCreate.latestInterview.medicine_notebook_image.url" @click="openMedicineNotebookImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
        </div>
      </div>
      <div v-else>
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">最新のお薬手帳画像</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">選択されていません</div>
      </div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">お薬の副作用が起きたことはありますか？</div>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-if="clinicReservationToCreate.interview.isSideEffect == 'なし'">なし</p>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview" v-if="clinicReservationToCreate.interview.isSideEffect == 'あり'">あり<br>{{clinicReservationToCreate.interview.sideEffect}}</p>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">ジェネリック医薬品を希望しますか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.generic | convertGenericValueToText}}</div>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">現在飲んでいるサプリメントはありますか？</div>
      <p class="p-onlineClinicInterviewConfirmation__itemInterview">
        {{clinicReservationToCreate.interview.isCurrentSupplement}}<br>{{clinicReservationToCreate.interview.currentSupplement}}
      </p>

      <div class="p-onlineClinicInterviewConfirmation__labelInterview">医師に伝えたいことや質問がありますか？</div>
      <div class="p-onlineClinicInterviewConfirmation__itemInterview">{{clinicReservationToCreate.interview.otherInformation}}</div>

      <div v-if="clinicReservationToCreate.interview.otherUsefulImage || clinicReservationToCreate.interview.isCopyOtherUsefulImage">
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">診察の質を向上させるための参考画像<br>※クリックすると拡大されます</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">
          <img v-if="clinicReservationToCreate.interview.otherUsefulImage" :src="clinicReservationToCreate.interview.otherUsefulImage" @click="openOtherUsefulImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
          <img v-else :src="clinicReservationToCreate.latestInterview.other_useful_image.url" @click="openOtherUsefulImageModal" class="p-onlineClinicInterviewConfirmation__cardImage">
        </div>
      </div>
      <div v-else>
        <div class="p-onlineClinicInterviewConfirmation__labelInterview">診察の質を向上させるための参考画像</div>
        <div class="p-onlineClinicInterviewConfirmation__itemInterview">選択されていません</div>
      </div>

    </div>
    <div>
      <ImageView v-if="isImageViewModal" :image="clinicReservationToCreate.interview.insuranceCardImage"></ImageView>
      <ImageView v-if="isRecipientCertificateImageViewModal" :image="clinicReservationToCreate.interview.recipientCertificateImage" ></ImageView>
      <ImageView v-if="!clinicReservationToCreate.interview.isCopyPillsPrescriptionImage && isPillsPrescriptionImageViewModal" :image="clinicReservationToCreate.interview.pillsPrescriptionImage" ></ImageView>
      <ImageView v-if="clinicReservationToCreate.interview.isCopyPillsPrescriptionImage && isPillsPrescriptionImageViewModal" :image="clinicReservationToCreate.latestInterview.pills_prescription_image.url" ></ImageView>
      <ImageView v-if="!clinicReservationToCreate.interview.isCopyMedicineNotebookImage && isMedicineNotebookImageViewModal" :image="clinicReservationToCreate.interview.medicineNotebookImage" ></ImageView>
      <ImageView v-if="clinicReservationToCreate.interview.isCopyMedicineNotebookImage && isMedicineNotebookImageViewModal" :image="clinicReservationToCreate.latestInterview.medicine_notebook_image.url" ></ImageView>
      <ImageView v-if="!clinicReservationToCreate.interview.isCopyOtherUsefulImage && isOtherUsefulImageViewModal" :image="clinicReservationToCreate.interview.otherUsefulImage" ></ImageView>
      <ImageView v-if="clinicReservationToCreate.interview.isCopyOtherUsefulImage && isOtherUsefulImageViewModal" :image="clinicReservationToCreate.latestInterview.other_useful_image.url" ></ImageView>
    </div>
  </div>
</template>
<script>
import Interview from '@/enums/online_clinic/Interview';
import EventBus from '@/event-bus';
import ImageView from '@/components/Common/ImageView';
const genericHash = {
  [Interview.generic.yes]: {
    labelText: 'ジェネリック薬を希望',
  },
  [Interview.generic.no]: {
    labelText: '先発薬を希望',
  },
  [Interview.generic.whichever]: {
    labelText: 'どちらでもよい',
  },
};

export default {
  components: {
    ImageView,
  },
  props: {
    clinicReservationToCreate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isImageViewModal: false,
      isRecipientCertificateImageViewModal: false,
      isPillsPrescriptionImageViewModal: false,
      isMedicineNotebookImageViewModal: false,
      isOtherUsefulImageViewModal: false,
    };
  },
  filters: {
    convertGenericValueToText(value) {
      return genericHash[value].labelText;
    },
  },
  created() {
    EventBus.$on('close-image-modal', () => {
      this.closeImageModal();
    });
  },
  methods: {
    openImageModal() {
      this.isImageViewModal = true;
    },
    closeImageModal() {
      this.isImageViewModal = false;
      this.isRecipientCertificateImageViewModal = false;
      this.isPillsPrescriptionImageViewModal = false;
      this.isMedicineNotebookImageViewModal = false;
      this.isOtherUsefulImageViewModal = false;
    },
    openRecipientCertificateImageModal() {
      this.isRecipientCertificateImageViewModal = true;
    },
    openPillsPrescriptionImageModal() {
      this.isPillsPrescriptionImageViewModal = true;
    },
    openMedicineNotebookImageModal() {
      this.isMedicineNotebookImageViewModal = true;
    },
    openOtherUsefulImageModal() {
      this.isOtherUsefulImageViewModal = true;
    },
    openHealthDiagnosisImageModal() {
      this.isHealthDiagnosisImageViewModal = true;
    },
  },
  computed: {
    smokingText() {
      if (this.clinicReservationToCreate.interview.smoking == '過去に吸っていた'){
        if (!this.clinicReservationToCreate.interview.smokingPastAgeFrom)  this.clinicReservationToCreate.interview.smokingPastAgeFrom = ''
        if (!this.clinicReservationToCreate.interview.smokingPastAgeTo)  this.clinicReservationToCreate.interview.smokingPastAgeTo = ''
        if (!this.clinicReservationToCreate.interview.smokingPastNumber)  this.clinicReservationToCreate.interview.smokingPastNumber = ''
        return this.clinicReservationToCreate.interview.smoking + '（' + this.clinicReservationToCreate.interview.smokingPastAgeFrom + '〜' + this.clinicReservationToCreate.interview.smokingPastAgeTo + '歳まで、1日' + this.clinicReservationToCreate.interview.smokingPastNumber + '本）'
      } else if (this.clinicReservationToCreate.interview.smoking == '吸っている') {
        if (!this.clinicReservationToCreate.interview.smokingPresentAgeFrom)  this.clinicReservationToCreate.interview.smokingPresentAgeFrom = ''
        if (!this.clinicReservationToCreate.interview.smokingPresentNumber)  this.clinicReservationToCreate.interview.smokingPresentNumber = ''
        return this.clinicReservationToCreate.interview.smoking + '（' + this.clinicReservationToCreate.interview.smokingPresentAgeFrom + '歳から、1日' + this.clinicReservationToCreate.interview.smokingPresentNumber + '本）'
      } else {
        return this.clinicReservationToCreate.interview.smoking
      }
    },
  }
};
</script>