<template>
  <base-modal>
    <template slot="header">
      <h1>
        クレジットカード情報登録
      </h1>
    </template>
    <template slot="body">
      <div class="stripeCardForm">
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <label class="control-label">カード番号</label>
            <div id="card-number" class="form-control"></div>
          </div>
          <div class="form-group">
            <label class="control-label">有効期限 月/年</label>
            <div id="card-expiry" class="form-control"></div>
          </div>
          <div class="form-group">
            <label class="control-label">セキュリティコード</label>
            <div id="card-cvc" class="form-control"></div>
          </div>
          <p v-if="error" class="error-text">{{ error }}</p>
          <p>
            <img :src="visaLogo" alt="visa" style="height: 20px"/>
            <img :src="masterCardLogo" alt="masterCard" style="height: 34px"/>
            <img :src="amexLogo" alt="amex" style="height: 24px"/>
            <img :src="jcbLogo" alt="jcb" style="height: 24px"/>
            がご利用いただけます
          </p>
          <div class="subscription-plan">
            <button type="submit" :disabled="loading" class="submit">
              {{ loading ? "登録中..." : "カードを登録" }}
            </button>
          </div>
        </form>
      </div>
    </template>
  </base-modal>

</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import amexLogo from '@/../assets/images/cards/amex.gif';
import visaLogo from '@/../assets/images/cards/visa.png';
import masterCardLogo from '@/../assets/images/cards/master_card.svg';
import jcbLogo from '@/../assets/images/cards/jcb.gif';
import * as actionTypes from '../store/action-types';
import axios from 'axios';

export default {
  name: 'StripeCardGetAuthorizationForm',
  computed: {
    amexLogo: () => amexLogo,
    visaLogo: () => visaLogo,
    masterCardLogo: () => masterCardLogo,
    jcbLogo: () => jcbLogo,
  },
  data() {
    return {
      stripe: null,
      elements: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      clientSecret: null,
      setupIntentId: null,
      error: null,
      loading: false,
    };
  },
  async mounted() {
    const token = document
      .getElementsByName('stripe-public-key')[0]
      .getAttribute('content');
    this.stripe = await loadStripe(token);
    this.elements = this.stripe.elements();

    // 個別の入力要素を作成
    this.cardNumber = this.elements.create("cardNumber");
    this.cardExpiry = this.elements.create("cardExpiry");
    this.cardCvc = this.elements.create("cardCvc");

    // 各要素をマウント
    this.cardNumber.mount("#card-number");
    this.cardExpiry.mount("#card-expiry");
    this.cardCvc.mount("#card-cvc");

    // 入力終了時に次のフィールドにフォーカスする制御
    this.cardNumber.on("change", (event) => {
      if (event.complete) {
        this.cardExpiry.focus();
      }
    });

    this.cardExpiry.on("change", (event) => {
      if (event.complete) {
        this.cardCvc.focus();
      }
    });

    // サーバーからSetupIntentのclient_secretを取得
    const response = await axios.post("/api/stripe/setup_intents");
    this.clientSecret = response.data.client_secret;
    this.setupIntentId = response.data.setup_intent_id;
  },
  methods: {
    async handleSubmit() {
      this.error = null;
      this.loading = true;

      try {
        const { setupIntent, error } = await this.stripe.confirmCardSetup(
          this.clientSecret,
          {
            payment_method: {
              card: this.cardNumber,
            },
          }
        );

        if (error) {
          this.error = error.message;
        } else {
          await axios.post("/api/stripe/customer_connection", {
            setup_intent_id: this.setupIntentId,
          });
          this.$store.dispatch(actionTypes.FETCH_CURRENT_USER);
          if (this.$route.path == '/online_clinic/clinic/reservation/new') {
            this.$emit('close-credit-modal', { cardRegistered: true });
          } else {
            this.backPatientFamilyProfiles();
          }
        }
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },
    backPatientFamilyProfiles() {
      this.$router.push(`/patient_family_profiles`);
    },
  },
};
</script>

<style>
  .form-control {
    padding: 12px 16px;
     height: auto;
    box-shadow: none;
    border-radius: 8px;
    border: solid 1px #d3d3d3;
  }
  .submit {
    display: block;
    color: #fff;
    background: linear-gradient(9.07deg, #00bcd4 0.06%, #2196f3 100%);
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding: 22px 0;
    margin: 20px 0;
    border-radius: 50px;
    text-decoration: none;
    width: 100%;
    border: none;
    cursor: pointer;
  }
  .error-text {
    color:#FF4545;
    background: #FFF0F0;
    height: 35px;
    padding: 0 10px;
    line-height: 35px;
    text-align: left;
    margin-top: 20px;
    font-size: 12px;
    font-weight: 500;
  }
</style>
