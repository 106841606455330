<template>
  <div class="p-onlineClinicSelectClinic">
    <loading :translucent="true" v-show="loading"></loading>
    <p class="p-onlineClinicSelectClinic__label">診療科目</p>
    <div>
      <select class="p-onlineClinicSelectClinic__select" id="p-onlineClinicSelectClinic__medical-departments" v-model="selectedMedicalDepartment" @change="fetchClinics()">
        <option disabled value="">診療科目を選択してください</option>
        <option v-for="md in medicalDepartments" :value="md" :key="md.id">
          {{ md.name }}
        </option>
      </select>
    </div>
    <p style="font-size:12px;">※診療科目に迷われた場合は、【内科】を選択し、問診に症状を詳しく入力してください。</p>

    <div v-if="isMedicalDepartmentSelected">
      <div class="p-onlineClinicSelectClinic__label-wrapper">
        <p style="margin: 15px 0 0 0" class="p-onlineClinicSelectClinic__label">連携診療機関</p>
      </div>
      <div style="margin: 12px 0" class="p-onlineClinicSelectClinic__radio">
        <div v-if="clinics.length > 0">
          <div v-for="clinic in clinics" :key="clinic.id">
            <label><input type="radio" :value="clinic" v-model="selectedClinic" @change="fetchAvailableDate()">{{ clinic.name }}</label>
          </div>
        </div>
        <div v-else>
          <p>選択できる診療機関がありません</p>
        </div>
      </div>

      <p style="font-size:12px;">※診療機関により、ご案内できる予約枠と処方せん発行時間が異なります。選択して最短の予約枠をご確認ください。</p>

      <div v-if="!isOpenClinic" class="p-onlineClinicSelectClinic__prescription-information">
        <p>※只今ご案内できる最短の予約枠<span>【{{latestDoctorScheduleDatetime}}】</span>の処方せんの発行は<span>【{{nextWorkingDateText}}】</span>となります。</p>
      </div>

      <div class="p-onlineClinicSelectClinic__label-wrapper">
        <button style="font-size:12px; margin-left:0;" class="p-onlineClinicSelectClinic__label-detail" @click="openClinicSelectionExplanationModal()">
          <img src="@/../assets/images/online_clinic/question-blue.svg"><span class="p-onlineClinicSelectClinic__label-detail--text">処方せん発行時間・助成資格について</span>
        </button>
      </div>

      <div class="p-onlineClinicSelectClinic__consent">
        <p class="p-onlineClinicSelectClinic__title">同意書</p>
        <p class="p-onlineClinicSelectClinic__text">
          私は、貴院の登録する「おうち病院 オンライン診療」にて遠隔診療サービスを受けるにあたって下記事項について同意します。
        </p>
        <p class="p-onlineClinicSelectClinic__text">
          ・遠隔診療、および処方せん・処方医薬品の配送サービスを受けるため、「おうち病院 オンライン診療」に登録した診療情報および個人情報が株式会社アナムネに提供されること。
        </p>
        <p class="p-onlineClinicSelectClinic__text">
          ・貴院が医療提供上必要だと判断した場合に、「おうち病院」に登録する他の医療機関に提供され共同で活用されること。
        </p>
      </div>
    </div>
    <div class="p-onlineClinicSelectClinic__footer">
      <button class="c-mainButton" @click="addExaminationData(); $emit('moveToNextStep'); $mixpanel.track('CLICK BUTTON', { page: '病院の選択（2）', buttonName: '同意して次へ', userId: currentUser.id, source: currentUser.source, isCvButton: true })"
              :disabled="!isFullyInputed">同意して次へ
      </button>
      <button class="p-onlineClinicSelectClinic__nobutton" @click="$emit('onCancel'); $mixpanel.track('CLICK BUTTON', { page: '病院の選択（2）', buttonName: 'キャンセル', userId: currentUser.id, source: currentUser.source })">キャンセル</button>
    </div>
    <clinic-selection-explanation-modal v-if="isClinicSelectionExplanationModalOpen" @close-modal="isClinicSelectionExplanationModalOpen = false"></clinic-selection-explanation-modal>
  </div>
</template>

<script>
import axios from 'axios';
import EventBus from '../../../../event-bus';
import Loading from '@/components/Common/Loading';
import ClinicSelectionExplanationModal from "@/components/online_clinic/clinics/reservations/dialog/ClinicSelectionExplanationModal.vue";
import moment from 'moment';
import {mapGetters} from "vuex";

export default {
  name: 'SelectClinic',
  components: { Loading, ClinicSelectionExplanationModal },
  props: {
    clinicReservationToCreate: {
      required: true,
      type: Object,
    },
  },
  created() {
    this.fetchMedicalDepartments();
  },
  data: () => {
    return {
      medicalDepartments: [],
      clinics: [],
      selectedMedicalDepartment: {},
      selectedClinic: {},
      availableDates: [],
      isAvailableDates: false,
      latestInterview: {},
      loading: false,
      latestDoctorSchedule: null, //予約できる最短の枠
      isOpenClinic: true,
      nextWorkingDateText: null,
      isClinicSelectionExplanationModalOpen: false
    };
  },
  computed: {
    isMedicalDepartmentSelected() {
      return this.selectedMedicalDepartment.id != undefined;
    },
    isClinicSelected() {
      return this.selectedClinic.id != undefined;
    },
    isFullyInputed() {
      return this.isMedicalDepartmentSelected && this.isClinicSelected && this.isAvailableDates;
    },
    latestDoctorScheduleDatetime() {
      if (this.latestDoctorSchedule) {
        const date = this.latestDoctorSchedule
        return moment(date.start).format("M/D") + ' ' + moment(date.start).format("HH:mm") + '〜' + moment(date.end).format("HH:mm")
      }
    },
    ...mapGetters(['currentUser'])
  },
  methods: {
    async fetchMedicalDepartments() {
      this.loading = true
      try {
        let res = await axios.get('/api/online_clinic/medical_departments');
        this.medicalDepartments = res.data;
      } catch {
        alert('ERROR');
      }
      this.loading = false
    },
    async fetchClinics() {
      this.initialParams()

      this.loading = true
      try {
        let res = await axios.get('/api/online_clinic/clinics', {
          params: { medical_department_id: this.selectedMedicalDepartment.id },
        });
        this.clinics = res.data;
      } catch {
        alert('ERROR');
      }
      await this.fetchLatestInterview()
      this.loading = false
    },
    async fetchLatestInterview() {
      try {
        let res = await axios.get('/api/online_clinic/interviews', {
          params: {
            patient_family_profile_id: this.clinicReservationToCreate.selectedFamilyProfile.id,
            interview_type_id: this.selectedMedicalDepartment.interview_type.id
          },
        });
        this.latestInterview = res.data;
      } catch(e) {
        alert(e);
      }
    },
    async fetchAvailableDate() {
      this.initialParams()

      this.loading = true
      try {
        let res = await axios.get('/api/online_clinic/doctor_schedules', {
          params: {
            available_all_date: true,
            clinic_id: this.selectedClinic.id,
            medical_department_id: this.selectedMedicalDepartment.id
          },
        });
        this.setAvailableDates(res.data)
        this.latestDoctorSchedule = res.data[0]
        this.isAvailableDates = true;
      } catch {
        alert('ERROR');
      }
      await this.fetchClinicSchedule()
      this.loading = false
    },
    addExaminationData() {
      const data = {
        medicalDepartment: this.selectedMedicalDepartment,
        clinic: this.selectedClinic,
        availableDates: this.availableDates,
        latestDoctorSchedule: this.latestDoctorSchedule,
        latestInterview: this.latestInterview,
        clinicSchedules: this.clinicSchedules,
      };
      this.$emit('addExaminationData', data);
    },
    setAvailableDates(schedules) {
      const availableDates = schedules.map((schedule) => moment(schedule.start).format("YYYY-MM-DD"));
      const uniqueAvailableDates = Array.from(new Set(availableDates))
      this.availableDates = uniqueAvailableDates;
    },
    async fetchClinicSchedule() {
      if(!this.latestDoctorSchedule) return

      let res = await axios.get('/api/online_clinic/clinic_schedules', {
        params: {
          clinic_id: this.selectedClinic.id,
          date: this.latestDoctorSchedule.end,
        },
      });
      this.clinicSchedules = res.data

      console.log('this.clinicSchedules', this.clinicSchedules)

      // クリニックに、スケジュール登録されていない場合、注意文言を表示しない
      if (this.clinicSchedules.length === 0 ) return

      this.setNextWorkingDateText(this.latestDoctorSchedule.end, this.clinicSchedules)
    },
    setNextWorkingDateText(datetime, clinicSchedules) {

      // 予約できる最短の枠以降に、クリニックスケジュールが登録されていない場合、注意文言を表示しない
      clinicSchedules = clinicSchedules.filter(function(schedule) {
        datetime = moment(datetime)
        const scheduleEnd = moment(schedule.end)
        return scheduleEnd.isAfter(datetime, 'minute')
      });
      if (clinicSchedules.length === 0) return

      // 予約できる最短の枠と同じ日で、予約できる最短の枠以降の時間にクリニックスケジュールがある場合（営業中）、注意文言を表示しない
      clinicSchedules.sort((a, b) => moment(a.start) - moment(b.start))
      const diffDays = moment(clinicSchedules[0].start).startOf('days').diff(moment(datetime).startOf('days'), 'days', true)
      // if(diffDays > 0){
      //   this.isOpenClinic = false
      // } else {
      //   return
      // }
      this.isOpenClinic = false // 当日にクリニックが空いていても、注意文言を表示する

      // 一番直近のスケジュール（翌営業日）を表示
      this.nextWorkingDateText = moment(clinicSchedules[0].start).format("M/D")

    },
    initialParams() {
      this.isOpenClinic = true
      this.nextWorkingDateText = null
      this.latestDoctorSchedule = null
    },
    openClinicSelectionExplanationModal() {
      this.isClinicSelectionExplanationModalOpen = true
    }
  },
  watch: {
    selectedMedicalDepartment: function(val) {
      // 選択された病院をクリアする
      if (this.selectedClinic.id) {
        this.selectedClinic.id = null;
      }
    },
    selectedClinic: function(val) {
      // 病院を選択し直したら、スケジュールを空にする
      EventBus.$emit('clear-doctor-schedule');
    },
  },
};
</script>
